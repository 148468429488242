import type { ActivityElement, KubernetesStepStatus, KubernetesTaskResourceStatusResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useEffect, useState } from "react";
import { useAnalyticViewDispatch } from "~/analytics/Analytics";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import KubernetesDeploymentFeedback from "~/components/K8sStatusFeedback/KubernetesDeploymentFeedback";
import { ExpansionButtons } from "~/components/form/index";
import Select from "~/primitiveComponents/form/Select/Select";
import KubernetesStepStatusExpander from "./KubernetesStepStatusExpander";
import styles from "./style.module.less";
export interface StepFilter {
    namespace?: string;
    targetName?: string;
    name?: string;
}
interface KubernetesStatusComponentProps {
    status: KubernetesTaskResourceStatusResource;
    activityLogs: Pick<ActivityElement, "Children">[];
    stepsWithKubernetesActions: string[];
    projectId?: string;
}
function KubernetesDeploymentStatus(props: KubernetesStatusComponentProps) {
    const dispatchView = useAnalyticViewDispatch(props.projectId);
    const [filter, setFilter] = useState<StepFilter>({});
    useEffect(() => {
        dispatchView("Navigate to Object Status Tab", { resource: "Kubernetes Deployment Status" });
        const timeoutId = window.setTimeout(() => dispatchView("View Object Status Tab", { resource: "Kubernetes Deployment Status" }), 5000);
        return () => window.clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function getStepStatus(stepName: string) {
        return props.status.KubernetesStepsStatus.find((v) => v.StepName === stepName);
    }
    function getStepActivity(stepName: string) {
        for (const activityLog of props.activityLogs) {
            for (const activity of activityLog.Children) {
                if (activity.Name === stepName) {
                    return activity;
                }
            }
        }
        return undefined;
    }
    return (<>
            <div className={styles.feedbackContainer}>
                <KubernetesDeploymentFeedback storageKey="Octopus.Callout.K8sStatusFeedback.DateDismissed"/>
            </div>
            <FilterSection filter={filter} statuses={props.status.KubernetesStepsStatus} setState={(filter) => setFilter(filter)}/>
            <ExpansionButtons expandAllOnMount={true}/>
            {props.stepsWithKubernetesActions.map((stepName) => {
            const activity = getStepActivity(stepName);
            if (!activity) {
                return null;
            }
            return <KubernetesStepStatusExpander key={stepName} stepName={stepName} status={getStepStatus(stepName)} filter={filter} stepActivity={activity}/>;
        })}
        </>);
}
function FilterSection(props: {
    statuses: KubernetesStepStatus[];
    filter: StepFilter;
    setState: (filter: StepFilter) => void;
}) {
    const controls: React.ReactNode[] = [];
    const distinctListOfItems = (filter: (kss: KubernetesStepStatus) => string[]) => props.statuses
        .flatMap(filter)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((value) => ({ value, text: value }));
    const targetNames = distinctListOfItems((kss) => kss.KubernetesObjects.map((o) => o.ClusterName));
    if (targetNames.length > 1) {
        controls.push(<div className={styles.selectWrap} key={1}>
                <Select value={props.filter.targetName} onChange={(targetName) => props.setState({ ...props.filter, targetName })} items={targetNames} allowClear={true} label="Filter by target" placeholder="All targets"/>
            </div>);
    }
    const namespaceNames = distinctListOfItems((kss) => kss.KubernetesObjects.map((o) => o.Namespace));
    if (namespaceNames.length > 1) {
        controls.push(<div className={styles.selectWrap} key={2}>
                <Select value={props.filter.namespace} onChange={(namespace) => props.setState({ ...props.filter, namespace })} items={namespaceNames} allowClear={true} label="Filter by namespace" placeholder="All namespaces"/>
            </div>);
    }
    return (<section className={`${styles.filterContainer} ${styles.leftRightContainer}`}>
            <div>
                <FilterSearchBox placeholder="Filter by name..." value={props.filter.name} onChange={(name: string) => props.setState({ ...props.filter, name })}/>
            </div>
            <div>{controls}</div>
        </section>);
}
export default KubernetesDeploymentStatus;
